import { gql } from '@apollo/client';
import EventEmitter2 from 'eventemitter2';
import { get } from 'lodash';
import uuidv4 from 'uuid/v4';
import {
  getTempUserIdCookie,
  setTempUserIdCookie,
  getUserAccessTokenCookie,
  getUserRefreshTokenCookie,
} from '@joybird/joystagram-components/dist/esm/localStorage';
import session from '../../services/session';
import SessionUser from '../../services/session-user';
import env from '../env';
import routes from '../../services/routes';
import isBotRequest from '@joybird/joystagram-components/dist/esm/isBotRequest';
import { isClient } from '../../app/globals';
import { GET_ROUTES_QUERY } from '@joybird/joystagram-components/dist/esm/exportedQueries';

/**
 * The `bootstrap` service provides the application with the data it requires
 * upon initial page load. The site *does not display* until this service has
 * been initialized.
 *
 * The expectation is that prior to going live, the data provided by this
 * service will be embedded within the HTML document that a visitor first loads
 * when visiting the site. This is important, as it will prevent the need for
 * any asynchronous calls to the server and will allow for an instantaneous
 * render of the site.
 */

class Bootstrap extends EventEmitter2 {
  initialized = false;

  async init(
    staticContext = null,
    client,
    cookies = null,
    setAuthTokenAndUser
  ) {
    let res = null,
      layouts = null,
      user = null,
      redirectRoutes = null,
      userResp = null,
      tempUser,
      printCampaignRedirectRoutes;
    if (staticContext && staticContext.initData) {
      res = staticContext.initData.data;
      routes.layouts = get(res, 'layouts');
      routes.routes = get(res, 'routes');
      routes.redirectRoutes = get(res, 'redirectRoutes');
      routes.printCampaignRedirectRoutes = get(
        res,
        'printCampaignRedirectRoutes.entries'
      );

      user = get(res, 'user');
      tempUser = get(res, 'tempUser');
      if (!user) {
        user = cookies.get('user');
      }
      if (!user && !tempUser) {
        let tempUserID = getTempUserIdCookie();
        if (!tempUserID) {
          tempUserID = uuidv4();
          if (isClient()) {
            setTempUserIdCookie(tempUserID);
          }
        }
        tempUser = { id: tempUserID, audiences: [] };
      }

      if (staticContext.queryParams)
        routes.serverQueryParams = staticContext.queryParams;
      if (staticContext.routeParams) {
        routes.serverRouteParams = staticContext.routeParams;
      }
    } else {
      [
        res,
        layouts,
        redirectRoutes,
        printCampaignRedirectRoutes,
        userResp,
      ] = await Promise.all([
        client.query({
          query: GET_ROUTES_QUERY,
        }),

        client.query({
          query: gql`
            query {
              layouts: getLayouts {
                id
                name
                components {
                  component
                  config
                }
              }
            }
          `,
        }),
        client.query({
          query: gql`
            query {
              redirectRoutes: getRedirectRoutes {
                from
                to
              }
            }
          `,
        }),
        client.query({
          query: gql`
            query {
              printCampaignRedirectRoutes: getPrintCampaignRedirects {
                entries {
                  from
                  to
                }
              }
            }
          `,
        }),
        client.query({
          ssr: false,
          query: gql`
            query User {
              user: currentCustomer {
                id
                first_name
                last_name
                email
                group_id
                is_designer
                user_discount
                is_impersonate
                audiences {
                  id
                  rank
                }
              }
            }
          `,
        }),
      ]);
      routes.layouts = get(layouts, 'data.layouts');
      routes.routes = get(res, 'data.routes');
      routes.redirectRoutes = get(redirectRoutes, 'data.redirectRoutes');
      routes.printCampaignRedirectRoutes = get(
        printCampaignRedirectRoutes,
        'data.printCampaignRedirectRoutes.entries'
      );
      user = get(userResp, 'data.user');
    }

    if (user) {
      session.user = new SessionUser(
        {
          id: user.id,
          first_name: user.first_name,
          last_name: user.last_name,
          email: user.email,
          group_id: user.group_id,
          is_designer: user.is_designer,
          user_discount: user.user_discount,
          is_impersonate: user.is_impersonate,
        },
        user.audiences
      );

      await setAuthTokenAndUser(
        {
          access_token: cookies
            ? cookies.get('uToken')
            : getUserAccessTokenCookie(),
          email: user.email,
          first_name: user.first_name,
          group_id: user.group_id,
          id: user.id,
          is_designer: user.is_designer,
          is_impersonate: user.is_impersonate,
          last_name: user.last_name,
          refresh_token: cookies
            ? cookies.get('rToken')
            : getUserRefreshTokenCookie(),
          user_discount: user.user_discount,
        },
        false // No need to trigger refetch of user data when initializing
      );
    } else if (tempUser) {
      //If no logged in user check localStorage for ID first
      let tempID = tempUser.id;
      let tempUserAudiences = tempUser.audiences;
      //Temporarily setting session user with empty audience due to await issue on the server
      session.user = new SessionUser(
        {
          id: tempID,
          first_name: 'Temp',
          last_name: 'User',
          email: 'tempuser@fiore.buzz',
        },
        tempUserAudiences
      );

      // Set temp user in local cache
      setTempUserIdCookie(tempID);
      // await client.mutate({
      //     mutation: SET_TEMP_USER_ID,
      //     variables: {
      //         id: tempID,
      //         email: session.user._user.email,
      //         first_name: session.user._user.first_name,
      //         last_name: session.user._user.last_name
      //     }
      // });
    } else {
      //If no logged in user check localStorage for ID first
      let tempID = cookies ? cookies.get(env.TEMP_UID) : null;
      let tempUserAudiences = [];
      //Temporarily setting session user with empty audience due to await issue on the server
      session.user = new SessionUser(
        {
          id: tempID,
          first_name: 'Temp',
          last_name: 'User',
          email: 'tempuser@fiore.buzz',
        },
        tempUserAudiences
      );
      if (!tempID) {
        tempID = uuidv4(); // FIXME: Investigate if IDs are unique in Alexandria, else try generating them in Alexandria.
        if (isClient()) {
          setTempUserIdCookie(tempID);
        }
      } else {
        //Retrieve audiences for temp User ID if any
        const tempUserAudiencesRes = await client.query({
          query: gql`
            query getUserAudiences($id: String) {
              getUserAudiences(id: $id) {
                id
                rank
              }
            }
          `,
          variables: {
            id: tempID,
          },
        });
        tempUserAudiences = get(tempUserAudiencesRes, 'data.getUserAudiences');
      }
      session.user = new SessionUser(
        {
          id: tempID,
          first_name: 'Temp',
          last_name: 'User',
          email: 'tempuser@fiore.buzz',
        },
        tempUserAudiences
      );

      // Set temp user in local cache
      setTempUserIdCookie(tempID);
      // await client.mutate({
      //     mutation: SET_TEMP_USER_ID,
      //     variables: {
      //         id: tempID,
      //         email: session.user._user.email,
      //         first_name: session.user._user.first_name,
      //         last_name: session.user._user.last_name
      //     }
      // });
    }

    this.initialized = true;
    if (!isClient()) this.emit('initialized');
  }
}

export default new Bootstrap();
